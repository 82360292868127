import { ApplicationRoutes } from '@werfy/routing';
import { NavigationItemInterface } from '@werfy/ui';

export const navigationItems: NavigationItemInterface[] = [
  { icon: 'house', name: 'Dashboard', route: '', isExact: true },
  { icon: 'file-user', name: 'Profiles', route: ApplicationRoutes.Profiles },
  { icon: 'chart-line', name: 'Campaigns', route: ApplicationRoutes.Campaigns },
  { icon: 'file-text', name: 'Message Templates', route: ApplicationRoutes.Templates },
  { icon: 'users', name: 'Candidates', route: ApplicationRoutes.Candidates },
  { icon: 'chart-simple', name: 'Reporting', route: ApplicationRoutes.Reporting },
];
