<wp-modal (close)="onCloseModal()">
  <span *ngIf="state !== 'success'" slot="title">Give us your feedback!</span>
  <span *ngIf="state === 'success'" slot="title">Thanks for your feedback!</span>
  <wp-form (submitForm)="onSubmit()" *ngIf="state !== 'success'" [formGroup]="form">
    <wp-field name="type">
      <wp-label>What describes your situation best?</wp-label>
      <wp-input-description>Help us narrow it down.</wp-input-description>
      <wp-radio-input formControlName="type">
        <wp-radio-button label="Problem" type="error" value="problem">
          Something is not working, broken, annoying or frustrating.
        </wp-radio-button>
        <wp-radio-button label="Idea" type="warning" value="idea">
          You have a suggestion for an improvement.
        </wp-radio-button>
        <wp-radio-button label="Question" type="info" value="question">Anything you want to know.</wp-radio-button>

        <wp-radio-button label="Remark" type="light" value="remark">Something you want to let us know.</wp-radio-button>

        <span slot="error">Please select one of the four types</span>
      </wp-radio-input>
    </wp-field>

    <wp-field name="subject">
      <wp-label>Subject</wp-label>
      <wp-input-description>A short description of what's going on.</wp-input-description>
      <wp-text-input formControlName="subject" placeholder="Sign-in problems">
        <span slot="error">Please fill in a general description of your situation</span>
      </wp-text-input>
    </wp-field>

    <wp-field name="description">
      <wp-label>Your message</wp-label>
      <wp-input-description>Any information is helpful!</wp-input-description>
      <wp-textarea-input formControlName="description" placeholder="Describe your situation in detail">
        <span slot="error">We can't help you if you don't tell us what's going on!</span>
      </wp-textarea-input>
    </wp-field>
  </wp-form>

  <wp-alert *ngIf="state === 'error'" label="Error processing feedback" type="error">
    <wp-icon icon="exclamation-circle" slot="icon"></wp-icon>
    We're having difficulties processing your feedback. Please try again or contact us if the problem persists.
  </wp-alert>

  <wp-alert *ngIf="state === 'success'" label="We've received your feedback" type="success">
    <wp-icon icon="check-circle" slot="icon"></wp-icon>
    <p>
      We'll get back to you as fast as we can. You can check the progress of your ticket in the link below. Don't worry,
      we'll notify you too!
      <wp-icon icon="face-smile-wink" set="light"></wp-icon>
    </p>
    <br />
    <wp-link (click)="onCloseModal()" [route]="ticketRoute">
      {{ this.form.controls.type.value | titlecase }}: {{ this.form.controls.subject.value }}
    </wp-link>
  </wp-alert>

  <wp-bar *ngIf="state !== 'success'" align="space-between" slot="footer">
    <wp-button (buttonClick)="onCloseModal()" alignIcon="left" icon="arrow-turn-down-left" type="transparent">
      Cancel
    </wp-button>
    <wp-button (buttonClick)="onSubmit()" [disabled]="state === 'pending'" icon="envelope-circle-check" type="accent">
      Submit Feedback
    </wp-button>
  </wp-bar>

  <wp-bar *ngIf="state === 'success'" align="space-between" slot="footer">
    <wp-button (buttonClick)="onCloseModal()" alignIcon="left" icon="xmark" type="transparent">
      Close this window
    </wp-button>
    <wp-button (buttonClick)="onViewTicket()" icon="arrow-up-right-from-square" type="accent">View my ticket</wp-button>
  </wp-bar>
</wp-modal>
