import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'wp-layout',
  standalone: true,
  templateUrl: './page.layout.html',
  imports: [RouterOutlet],
})
export class PageLayout {}
