import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthenticationService, AuthenticationStore, UserResponse } from '@werfy/core';
import { CreateTicketModal } from '@werfy/modals';
import { ApplicationRoutes } from '@werfy/routing';
import {
  AvatarComponent,
  BubbleComponent,
  IconComponent,
  MenuComponent,
  MenuItemComponent,
  MetaNavigationComponent,
  MetaNavigationItemComponent,
  NavigationComponent,
  NavigationItemComponent,
  NavigationItemInterface,
  NotificationDotComponent,
  SidebarPageComponent,
  SkSidebarComponent,
  UserSummaryComponent,
} from '@werfy/ui';
import { filter, Observable } from 'rxjs';
import { navigationItems } from './navigation-items';

@Component({
  selector: 'wp-sidebar-layout',
  standalone: true,
  templateUrl: './sidebar.layout.html',
  imports: [
    RouterOutlet,
    SidebarPageComponent,
    SkSidebarComponent,
    NavigationComponent,
    NavigationItemComponent,
    NgForOf,
    MetaNavigationComponent,
    IconComponent,
    MetaNavigationItemComponent,
    NotificationDotComponent,
    NgIf,
    AsyncPipe,
    AvatarComponent,
    UserSummaryComponent,
    MenuComponent,
    MenuItemComponent,
    BubbleComponent,
    CreateTicketModal,
  ],
})
export class SidebarLayout implements AfterViewInit {
  public navigationItems: NavigationItemInterface[] = navigationItems;
  public user$!: Observable<UserResponse | undefined>;
  public showCreateTicketModal: boolean = false;

  @ViewChild(MetaNavigationComponent) public meta!: MetaNavigationComponent;

  @ViewChild(SidebarPageComponent) private sidebarPage!: SidebarPageComponent;

  constructor(
    private authenticationService: AuthenticationService,
    authenticationStore: AuthenticationStore,
    private router: Router,
  ) {
    this.user$ = authenticationStore.getUser$();
  }

  public ngAfterViewInit(): void {
    this.router.events.pipe(filter((event: unknown) => event instanceof NavigationEnd)).subscribe({
      next: () => this.sidebarPage.scrollToTop(),
    });
  }

  public onOrganizations(): void {
    this.meta.onExpand();
    this.router.navigate([ApplicationRoutes.Root, ApplicationRoutes.Organizations]).then();
  }

  public onTickets(): void {
    this.meta.onExpand();
    this.router.navigate([ApplicationRoutes.Root, ApplicationRoutes.Tickets]).then();
  }

  public onLogout(): void {
    this.authenticationService.logout$().subscribe(() => {
      this.router.navigate([ApplicationRoutes.Root], { onSameUrlNavigation: 'reload' }).then();
    });
  }

  public formatName(user: UserResponse): string {
    if (user.displayName) {
      return user.displayName;
    }
    return `${user.firstName} ${user.lastName}`;
  }
}
